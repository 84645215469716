import Axios from '@/services/Axios';
import { TokenUtils } from '@/utils/TokenUtils';
import { CashCloseData } from '../models/PurchaseOrder/CashCloseData';
import { PurchaseOrderSingleItem } from '../models/PurchaseOrderSingleItem';

const resource = '/purchase-order';

export default {
  async createCashStart(amount, storeId) {
    const payload = { amount, storeId };
    let response = null;
    try {
      response = await Axios.post('/cash-start', payload);
    } catch (e) {
      console.error(e);
      response = e;
      return { error: 'asdasd' };
    }
    return response;
  },

  async getActiveCashStart(storeId) {
    const params = {
      storeId,
    };
    let response = null;
    try {
      response = await Axios.get('/cash-start/active', { params });
    } catch (e) {
      console.error(e);
      response = e;
      return { error: 'asdasd' };
    }
    return response;
  },

  async updateCashStart(id, amount) {
    const payload = { amount };
    let response = null;
    try {
      response = await Axios.put(`/cash-start/${id}`, payload);
    } catch (e) {
      console.error(e);
      response = e;
      return { error: 'asdasd' };
    }
    return response;
  },

  async getCashCloseData() {
    let response = null;
    let data = await Axios.get('cash-close/data');
    data = data.data;
    response = new CashCloseData(data.ordersTotalAmount, data.cashAmount,
      data.debitCardAmount, data.creditCardAmount, data.checkAmount,
      data.transferAmount, data.orders);
    return response;
  },

  async CashClose() {
    let response = null;
    try {
      response = await Axios.put('cash-close');
    } catch (e) {
      console.error(e);
      response = e;
      return { error: 'asdasd' };
    }
    return response;
  },

  async getPurchaseOrderData(id) {
    let response = null;
    let data = await Axios.get(`${resource}/${id}`);
    data = data.data;
    response = new PurchaseOrderSingleItem(data.id, data.employee, data.client, data.total,
      data.type, data.createdDt, data.isClosed, data.cashPayments, data.debitCardPayments,
      data.creditCardPayments, data.checkPayments,
      data.transferPayments, data.products);
    return response;
  },

  async getTicket() {
    const headers = {
      Authorization: `Bearer ${TokenUtils.getToken()}`,
    };

    try {
      const response = await Axios.get('ticket', { headers });
      return response.data;
    } catch (e) {
      console.error(e);
      return { error: e };
    }
  },
};
