<template>
<v-container class="pt-0 full-height">
  <v-row>
    <v-col>
      <h1 class="section-title">Inicio de caja</h1>
    </v-col>
  </v-row>

  <!----------------- IMPORTES TOTALES (EFECTIVO, TARJETA) ------------------------->
  <v-row>
    <v-col cols="12" sm="4">
      <v-currency-field outlined label="Cantidad" color="secondary"
      v-model="amount"></v-currency-field>
    </v-col>

    <v-col cols="12" sm="4">
      <v-btn @click="saveCashStart" color="secondary">Guardar</v-btn>
    </v-col>
  </v-row>
  <!----------------- IMPORTES TOTALES (EFECTIVO, TARJETA) ------------------------->
</v-container>
</template>

<script>
import Swal from 'sweetalert2';
import { mapGetters } from 'vuex';
import MiscService from '../services/MiscService';

export default {
  name: 'cash-start',
  data: () => ({
    amount: null,
    cashStartId: null,
  }),
  computed: {
    ...mapGetters({
      authUserData: 'authUserData',
    }),
  },
  methods: {
    async saveCashStart() {
      if (!this.cashStartId) {
        const { store } = this.authUserData.user_claims;
        const resp = await MiscService.createCashStart(this.amount, store);
        if (resp.error) {
          Swal.fire('Error', 'Hubo un error al guardar el inicio de caja', 'error');
          return;
        }
        Swal.fire('Correcto', 'Se guardó el inicio de caja', 'success');
      } else {
        const resp = await MiscService.updateCashStart(this.cashStartId, this.amount);
        if (resp.error) {
          Swal.fire('Error', 'Hubo un error al actualizar el inicio de caja', 'error');
          return;
        }
        Swal.fire('Correcto', 'Se actualizó el inicio de caja', 'success');
      }
      await this.setupData();
    },
    async setupData() {
      const { store } = this.authUserData.user_claims;
      let data = await MiscService.getActiveCashStart(store);
      data = data.data;
      if (data) {
        this.amount = data.amount;
        this.cashStartId = data.id;
      }
      // this.$store.dispatch('setCashCloseData', data);
    },
  },
  async mounted() {
    this.setupData();
  },
};
</script>

<style scoped>
.fixed-row {
  position: fixed;
  z-index: 1;
}
.section-title {
  font-weight: 300;
  font-size: 2.5rem;
  border-bottom: 1px black solid;
}
.section-subtitle {
  font-weight: 300;
  font-size: 2rem;
}

.headline {
  font-size: 1.8rem!important;
}
.orders-container {
  display: flex !important;
  height: 100% !important;
  flex-flow: column !important;
}
</style>
